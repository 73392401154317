<template>
  <div class="atom-carbon-saver-logo d-flex flex-row align-center justify-center">
    <img src="../../assets/Logo.svg" alt="Logo Carbon Saver" class="mr-3 img-logo"/>
    <img src="../../assets/carbon-saver-title-nude.svg" class="img-title" alt="Carbon Saver" v-if="dark"/>
    <img src="../../assets/carbon-saver-title-dark-forest.svg" class="img-title" alt="Carbon Saver" v-else/>
  </div>
</template>

<style lang="scss" scoped>
.img-logo {
  max-height: 48px;
}

.img-title {
  position: relative;
  max-height: 16px
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AtomCarbonSaverLogo',
  props: {
    dark: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  }
})
</script>
