<template>
  <v-app-bar class="molecule-app-bar"
             flat
             :dark="dark"
             :color="$attrs.color == null ? (dark ? 'cs-green-forest' : 'cs-soft-white') : $attrs.color"
             v-bind="$attrs">
    <div class="d-flex flex-grow-1 align-center mx-n2">
      <atom-button v-if="backIcon"
                   fab small text
                   icon="mdi-arrow-left"
                   :color="dark ? 'cs-soft-white' : 'cs-green-forest'"
                   @click.stop="() => $emit('backIconClick')">
      </atom-button>
      <v-app-bar-nav-icon v-if="navIcon"
                          class="mx-2"
                          :color="dark ? 'cs-soft-white' : 'cs-green-forest'"
                          @click.stop="() => $emit('navIconClick')">
      </v-app-bar-nav-icon>
      <router-link :to="{name: 'home'}" class="ml-6">
        <atom-carbon-saver-logo :dark="dark" v-if="carbonSaverLogo">
        </atom-carbon-saver-logo>
      </router-link>
      <div class="d-flex flex-grow-1 align-center mx-2" :class="{'pl-6': navIcon || carbonSaverLogo}">
        <slot></slot>
      </div>
    </div>
  </v-app-bar>
</template>
<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import AtomCarbonSaverLogo from '../atoms/AtomCarbonSaverLogo.vue'
import AtomButton from '../atoms/AtomButton.vue'

export default defineComponent({
  name: 'MoleculeAppBar',
  components: { AtomButton, AtomCarbonSaverLogo },
  props: {
    dark: {
      type: Boolean as PropType<boolean>
    },
    backIcon: {
      type: Boolean as PropType<boolean>
    },
    navIcon: {
      type: Boolean as PropType<boolean>
    },
    carbonSaverLogo: {
      type: Boolean as PropType<boolean>
    }
  }
})
</script>
<style lang="scss">
.molecule-app-bar {
}
</style>
